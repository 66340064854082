import React, { memo } from "react"
import PropTypes from "prop-types"
import classes from "components/inputs/Input.module.scss"
import HelperText from "components/inputs/HelperText"

const SelectInput = memo(
  ({
    register,
    hasError,
    name,
    label,
    helperText,
    validation,
    options,
    defaultValue,
  }) => (
    <div className={classes.root}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.selectInputWrapper}>
        <select
          className={classes.input}
          name={name}
          ref={register(validation)}
          defaultValue={defaultValue}
        >
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className={classes.caret} />
      </div>
      {helperText && (
        <div className="pt-1">
          <HelperText isError={hasError} message={helperText} />
        </div>
      )}
    </div>
  )
)

SelectInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  defaultValue: PropTypes.string,
}

SelectInput.defaultProps = {
  validation: {},
  defaultValue: undefined,
  helperText: null,
}

export default SelectInput
