import React, { memo, useCallback } from "react"
import PropTypes from "prop-types"
import { useController } from "react-hook-form"
import HelperText from "components/inputs/HelperText"
import classes from "components/inputs/Input.module.scss"

const TextAreaInput = memo(
  ({
    register,
    hasError,
    control,
    name,
    label,
    maxLength,
    validation,
    helperText,
    ...rest
  }) => {
    const {
      field: { ref, onChange, value, onBlur },
    } = useController({
      name,
      control,
      rules: validation,
      defaultValue: "",
    })

    const currentLength = value.length

    const onChangeWrapper = useCallback(
      event => {
        const {
          target: { value: currentValue },
        } = event

        if (value && maxLength < currentValue.length) {
          return
        }
        onChange(event)
      },
      [value, maxLength, onChange]
    )

    return (
      <div className={classes.root}>
        {label && (
          <label className={`${classes.label} d-flex justify-content-between`}>
            <span>{label}</span>
            <span>{`${currentLength}/${maxLength}`}</span>
          </label>
        )}
        <textarea
          className={classes.input}
          style={{
            resize: "none",
            height: 160,
          }}
          ref={ref}
          name={name}
          onChange={onChangeWrapper}
          value={value}
          onBlur={onBlur}
          {...rest}
        />
        {helperText && (
          <div className="pt-1">
            <HelperText isError={hasError} message={helperText} />
          </div>
        )}
      </div>
    )
  }
)

TextAreaInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

TextAreaInput.defaultProps = {
  validation: {},
  helperText: null,
}

export default TextAreaInput
