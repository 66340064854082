import React, { memo } from "react"
import PropTypes from "prop-types"
import classes from "components/inputs/Input.module.scss"
import HelperText from "components/inputs/HelperText"

const TextInput = memo(
  ({
    register,
    hasError,
    type,
    name,
    label,
    validation,
    helperText,
    ...rest
  }) => (
    <div className={classes.root}>
      {label && <label className={classes.label}>{label}</label>}
      <input
        className={classes.input}
        type={type}
        name={name}
        ref={register(validation)}
        {...rest}
      />
      {helperText && (
        <div className="pt-1">
          <HelperText isError={hasError} message={helperText} />
        </div>
      )}
    </div>
  )
)

TextInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

TextInput.defaultProps = {
  type: "text",
  validation: {},
  helperText: null,
}

export default TextInput
