import React from "react"
import PropTypes from "prop-types"
import TextInputWrapper from "src/components/formWrappers/TextInputWrapper"
import SelectInputWrapper from "src/components/formWrappers/SelectInputWrapper"
import SubmitButton from "src/components/inputs/SubmitButton"
import { selectOptions, validation } from "src/components/Forms/constants"
import { ClearAllErrors, clearErrorByName } from "src/constants/functions"

const FirstStep = ({ onSubmit, formMethods }) => {
  return (
    <form onSubmit={onSubmit}>
      <TextInputWrapper
        type="text"
        name="name"
        label="What's your name?"
        placeholder="Enter your name"
        validation={validation.name}
        onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
      />
      <TextInputWrapper
        type="email"
        name="email"
        label="Enter your email"
        placeholder="user@example.com"
        validation={validation.email}
        onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
      />
      <SelectInputWrapper
        label="What's your goal?"
        name="goal"
        options={selectOptions}
      />
      <SubmitButton
        onClick={() => ClearAllErrors(formMethods.clearErrors)}
        disabled={!formMethods.formState.isValid}
      >
        Next
      </SubmitButton>
    </form>
  )
}

FirstStep.propTypes = {
  formMethods: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default FirstStep
