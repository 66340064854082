import React, { useContext } from "react"
import ContactForm from "../../Forms/ContactForm"
import VacancyForm from "src/components/Forms/VacancyForm"
import { FormOpenContext } from "../BasicLayout/BasicLayout"
import classes from "../../Forms/ContactForm.module.scss"
import clsx from "clsx"

const FormsCallBack = () => {
  let {
    isOpenContactForm,
    handleCloseContactForm,
    isOpenCV,
    isFormSentSuccessfully,
    setIsFormSentSuccessfully,
  } = useContext(FormOpenContext)
  return (
    <>
      <div
        onClick={handleCloseContactForm}
        className={isOpenContactForm ? classes.back : ""}
      >
        <div
          className={clsx(classes.root, { [classes.open]: isOpenContactForm })}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {isOpenCV ? (
            <VacancyForm
              isFormSentSuccessfully={isFormSentSuccessfully}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              open={isOpenContactForm}
              onClose={handleCloseContactForm}
            />
          ) : (
            <ContactForm
              isFormSentSuccessfully={isFormSentSuccessfully}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              open={isOpenContactForm}
              onClose={handleCloseContactForm}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default FormsCallBack
