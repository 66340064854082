import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import DefaultHeader from "components/layout/DefaultHeader/DefaultHeader"
import classes from "./BasicLayout.module.scss"
import BasicFooter from "../BasicFooter/BasicFooter"
import FormsCallBack from "../FormsCallBack/FormsCallBack"
import OpenFormButton from "../OpenFormButton/OpenFormButton"
import clsx from "clsx"
import smoothScroll from "smoothscroll-polyfill"

export const FormOpenContext = React.createContext({
  isOpenCV: false,
  isFormOpen: false,
  isFormSentSuccessfully: false,
})
const BasicLayout = ({
  children,
  className,
  containerClassName,
  withoutFooter = false,
  stickyBtn,
  openCV,
  titleStickyBtn,
}) => {
  const [isOpenContactForm, setIsOpenContactForm] = useState(false)
  const [isFormSentSuccessfully, setIsFormSentSuccessfully] = useState(false)
  useEffect(() => {
    smoothScroll.polyfill()
  }, [])
  const [isOpenCV, setIsOpenCV] = useState(true)

  useEffect(() => {
    if (isOpenContactForm) {
      document.body.classList.add("hide")
    } else {
      document.body.classList.remove("hide")
    }
  }, [isOpenContactForm])

  const handleOpenContactForm = useCallback((isNeedOpenCV = false) => {
    setIsOpenContactForm(true)
    if (isNeedOpenCV) {
      setIsOpenCV(true)
    } else {
      setIsOpenCV(false)
    }
  }, [])

  const handleCloseContactForm = useCallback(() => {
    setIsOpenContactForm(false)
    setTimeout(() => {
      setIsFormSentSuccessfully(false)
    }, 200) // give a form  time to close and don't show a new clear form;
  }, [])

  return (
    <FormOpenContext.Provider
      value={{
        isOpenContactForm,
        handleOpenContactForm,
        handleCloseContactForm,
        isOpenCV,
        isFormSentSuccessfully,
        setIsFormSentSuccessfully,
      }}
    >
      <div className={`${classes.root} ${className}`}>
        <DefaultHeader onOpenContactForm={handleOpenContactForm} />
        <main className={clsx(classes.container, containerClassName)}>
          {children}
        </main>
        {stickyBtn && (
          <OpenFormButton
            title={titleStickyBtn}
            isOpenCV={openCV}
            stickyMobile={true}
          />
        )}
        {!withoutFooter && <BasicFooter />}
        <FormsCallBack />
      </div>
    </FormOpenContext.Provider>
  )
}

BasicLayout.propTypes = {
  backBtnUrl: PropTypes.string,
  className: PropTypes.string,
  hideSocials: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

BasicLayout.defaultProps = {
  className: "",
  backBtnUrl: "/",
}

export default BasicLayout
