import React from "react"
import classes from "../ContactForm.module.scss"
import Image from "../../image"

const FormSentSuccessfully = () => {
  return (
    <div className={classes.successWrapper}>
      <Image src="success.png" className={classes.pictureSuccess} />
      <h3 className={classes.successDescription}>
        Your message has been sent!
      </h3>
    </div>
  )
}

export default FormSentSuccessfully
