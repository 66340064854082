import React from "react"
import PropTypes from "prop-types"
import classes from "components/inputs/Input.module.scss"
import { useFormContext } from "react-hook-form"

const SubmitButton = ({ children, disabled, style, onClick }) => {
  const {
    formState: { isSubmitting, isValidating },
  } = useFormContext()

  return (
    <button
      type="submit"
      className={classes.submitBtn}
      disabled={disabled || isSubmitting || isValidating}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

SubmitButton.defaultProps = {
  disabled: false,
}

export default SubmitButton
