export const phoneRegex = /\+[0-9]{2}\s\([0-9]{3}\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/
export const phoneError = "The phone format is +38 (999) 999 99 99"

export const validation = {
  name: {
    required: { value: true, message: "This field is required" },
  },
  email: {
    required: { value: true, message: "This field is required" },
  },
  message: {
    required: { value: true, message: "This field is required" },
  },
  phone: {
    required: { value: true, message: "This field is required" },
    pattern: {
      value: phoneRegex,
      message: phoneError,
    },
  },
  fileCV: {
    required: { value: true, message: "This field is required" },
  },
}

export const selectOptions = [
  { value: "needDev", label: "I need rock star developers" },
  { value: "needDesigner", label: "I need rock star designers" },
  { value: "joinTeam", label: "I want to join your awesome team" },
  { value: "chat", label: "I just want to chat" },
  { value: "testForm", label: "I'm testing the form" },
]
