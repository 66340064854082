import React from "react"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import appConfig from "src/constants/appConfig"
import { validation } from "src/components/Forms/constants"
import SubmitButton from "src/components/inputs/SubmitButton"
import TextAreaWrapper from "src/components/formWrappers/TextAreaWrapper"
import classes from "../../inputs/Input.module.scss"
import { ClearAllErrors, clearErrorByName } from "src/constants/functions"
import Image from "../../image"

const SecondStep = ({
  reCaptchaToken,
  onReCaptchaVerify,
  control,
  onSubmit,
  formMethods,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <TextAreaWrapper
        name="message"
        label="Ask your question"
        maxLength={450}
        placeholder="Enter your message"
        validation={validation.message}
        control={control}
        onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
      />
      <div className="mb-4">
        <ReCAPTCHA
          sitekey={appConfig.reCaptchaSiteKey}
          render="explicit"
          onChange={onReCaptchaVerify}
        />
      </div>
      <SubmitButton
        disabled={!reCaptchaToken}
        onClick={() => ClearAllErrors(formMethods.clearErrors)}
      >
        <Image src="icon-smile.png" className={classes.buttonImage} />
        Let's Rock
      </SubmitButton>
    </form>
  )
}

SecondStep.propTypes = {
  control: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  reCaptchaToken: PropTypes.string,
  formMethods: PropTypes.object,
}

SecondStep.defaultProps = {
  reCaptchaToken: null,
}

export default SecondStep
