import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { useForm, FormProvider } from "react-hook-form"
import SvgIcon from "src/components/SvgIcon"
import Close from "src/assets/icons/close.svg"
import classes from "src/components/Forms/ContactForm.module.scss"
import TextInputWrapper from "src/components/formWrappers/TextInputWrapper"
import {
  validation,
  phoneRegex,
  phoneError,
} from "src/components/Forms/constants"
import SubmitButton from "src/components/inputs/SubmitButton"
import FileInputWrapper from "src/components/formWrappers/FileInputWrapper"
import { clearErrorByName, ClearAllErrors } from "src/constants/functions"
import FormSentSuccessfully from "./components/FormSendSuccessfully"

const ContactForm = ({
  onClose,
  isFormSentSuccessfully,
  setIsFormSentSuccessfully,
}) => {
  const formMethods = useForm({ mode: "onChange" })
  const FileInputDefaultSubscription = "+ Add .PDF or .DOC"
  const handleDeleteFile = e => {
    document.getElementById("CV_uploader").value = ""
    e.preventDefault()
    setChosenFile(FileInputDefaultSubscription)
  }

  const [chosenFile, setChosenFile] = useState(FileInputDefaultSubscription)

  const phoneNumberName = "phoneNumber"

  const handleCloseForm = () => {
    onClose()
    clearData()
  }

  const clearData = () => {
    setChosenFile(FileInputDefaultSubscription)
    formMethods.reset({})
  }

  const checkPhoneNumberFormat = e => {
    // we show errors after leaving this field
    formMethods.clearErrors()
    if (e.target.value.length === 0) {
      return
    }
    e.target.value = e.target.value.replace(/[^0-9]/g, "")
    if (e.target.value[0] !== "+") {
      e.target.value = "+" + e.target.value
    }
    if (e.target.value.length > 3) {
      e.target.value =
        e.target.value.slice(0, 3) +
        " " +
        e.target.value.slice(3, e.target.value.length)
    }
    if (e.target.value.length > 4) {
      e.target.value =
        e.target.value.slice(0, 4) +
        "(" +
        e.target.value.slice(4, e.target.value.length)
    }
    if (e.target.value.length > 8) {
      e.target.value =
        e.target.value.slice(0, 8) +
        ")" +
        e.target.value.slice(8, e.target.value.length)
    }
    if (e.target.value.length > 9) {
      e.target.value =
        e.target.value.slice(0, 9) +
        " " +
        e.target.value.slice(9, e.target.value.length)
    }
    if (e.target.value.length > 13) {
      e.target.value =
        e.target.value.slice(0, 13) +
        " " +
        e.target.value.slice(13, e.target.value.length)
    }
    if (e.target.value.length > 16) {
      e.target.value =
        e.target.value.slice(0, 16) +
        " " +
        e.target.value.slice(16, e.target.value.length)
    }
    if (e.target.value.length > 19) {
      e.target.value = e.target.value.slice(0, 19)
    }
  }

  function checkPhoneKey(e) {
    if (
      (e.key >= "0" && e.key <= "9") ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "Delete" ||
      e.key === "Backspace"
    ) {
      return
    }
    e.preventDefault()
  }

  const phoneNumberBlur = e => {
    clearErrorByName(e, formMethods.clearErrors)
    // if  this field doesn't support format
    if (!phoneRegex.test(e.target.value))
      formMethods.setError(phoneNumberName, {
        message: phoneError,
      })
  }

  const submitSendForm = useCallback(async ({ name, phoneNumber, CV }) => {
    const formData = new FormData()
    formData.append("name", name)
    formData.append("phoneNumber", phoneNumber)
    formData.append("CVfile", document.getElementById("CV_uploader").files[0])
    const currentURL = document.location.pathname
    if (currentURL.includes("vacancy")) {
      const chunkPath = currentURL.split("/")
      formData.append("vacancy", chunkPath[chunkPath.length - 1])
    }
    try {
      const result = await fetch(
        `${process.env.GATSBY_HOST_URL}/scripts/sendCVToEmail.php`,
        {
          method: "POST",
          mode: "cors",
          body: formData,
        },
      )
      clearData()
      setIsFormSentSuccessfully(true)
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <>
      <div className={classes.closWrapper}>
        <SvgIcon
          icon={Close}
          className={classes.closeForm}
          onClick={handleCloseForm}
        />
      </div>
      {isFormSentSuccessfully ? (
        <FormSentSuccessfully />
      ) : (
        <>
          <h2 className={`${classes.title} mb-4`}>
            Do you have any questions?
          </h2>
          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(submitSendForm)}
              encType="multipart/form-data"
            >
              <TextInputWrapper
                type="text"
                name="name"
                label="What's your name?"
                placeholder="Enter your name"
                validation={validation.name}
                onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
              />
              <TextInputWrapper
                type="text"
                onKeyDown={checkPhoneKey}
                onChange={checkPhoneNumberFormat}
                placeholder="+38 (095) 122 33 44"
                name={phoneNumberName}
                validation={validation.phone}
                label="Enter your phone number"
                onBlur={e => phoneNumberBlur(e)}
              />
              <FileInputWrapper
                FileInputDefaultSubscription={FileInputDefaultSubscription}
                deleteFile={handleDeleteFile}
                chosenFile={chosenFile}
                setChosenFile={setChosenFile}
                name="CV"
                label="Upload  your CV"
                validation={validation.fileCV}
                accept=".pdf,.doc,.docx"
                onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
              />
              <SubmitButton
                disabled={!formMethods.formState.isValid}
                onClick={() => ClearAllErrors(formMethods.clearErrors)}
              >
                Send CV
              </SubmitButton>
            </form>
          </FormProvider>
        </>
      )}
      <div className="flex-fill d-flex flex-column justify-content-end">
        <p className={classes.infoBlock}>
          We are located in Zaporizhzhia, Ukraine. Also you can contact us via{" "}
          <a className={classes.infoLink} href="tel:+380953159373">
            phone
          </a>{" "}
          or via{" "}
          <a
            className={classes.infoLink}
            href="skype:live:e4187abd1073736c?chat"
          >
            Skype
          </a>
          .
        </p>
      </div>
    </>
  )
}

ContactForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFormSentSuccessfully: PropTypes.bool.isRequired,
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
}

export default ContactForm
