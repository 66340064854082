import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useForm, FormProvider } from "react-hook-form"
import FirstStep from "src/components/Forms/components/FirstStep"
import SecondStep from "src/components/Forms/components/SecondStep"
import SvgIcon from "src/components/SvgIcon"
import Back from "src/assets/icons/back.svg"
import Close from "src/assets/icons/close.svg"
import classes from "./ContactForm.module.scss"
import FormSentSuccessfully from "./components/FormSendSuccessfully"

const stepsConfig = {
  first: "first",
  second: "second",
}

const ContactForm = ({
  onClose,
  isFormSentSuccessfully,
  setIsFormSentSuccessfully,
}) => {
  const formMethods = useForm({ mode: "onChange" })
  const [currentStep, setCurrentStep] = useState(stepsConfig.first)
  const [formValues, setFormValues] = useState(null)
  const [reCaptchaToken, setReCaptchaToken] = useState(null)

  const reCaptchaVerify = useCallback(token => {
    setReCaptchaToken(token)
  }, [])

  const submitFirstStep = useCallback(data => {
    setFormValues(data)
    setCurrentStep(stepsConfig.second)
  }, [])

  const backToFirstStep = useCallback(() => {
    setCurrentStep(stepsConfig.first)
    setReCaptchaToken(null)
    formMethods.reset({ ...formValues })
  }, [formMethods, formValues])

  const handleCloseForm = useCallback(() => {
    onClose()
    clearData()
  }, [formMethods, onClose])

  const clearData = () => {
    setCurrentStep(stepsConfig.first)
    formMethods.reset({})
  }
  const submitSecondStep = useCallback(
    async ({ message }) => {
      const formData = new FormData()
      formData.append("name", formValues.name)
      formData.append("email", formValues.email)
      formData.append("subject", formValues.goal)
      formData.append("text", message)
      formData.append("g-recaptcha-response", message)
      setFormValues(null)

      try {
        const result = await fetch(
          `${process.env.GATSBY_HOST_URL}/scripts/contact.php`,
          {
            method: "POST",
            mode: "cors",
            body: formData,
          },
        )
        clearData()
        setIsFormSentSuccessfully(true)
      } catch (e) {
        console.log(e)
      }
    },
    [formValues],
  )

  return (
    <>
      {isFormSentSuccessfully ? (
        <>
          <div className={classes.closWrapper}>
            <SvgIcon
              icon={Close}
              className={classes.closeForm}
              onClick={handleCloseForm}
            />
          </div>
          <FormSentSuccessfully />
        </>
      ) : (
        <>
          <div
            className={clsx(classes.closWrapper, {
              "justify-content-end": currentStep === stepsConfig.first,
              "justify-content-between": currentStep === stepsConfig.second,
            })}
          >
            {currentStep === stepsConfig.second && (
              <SvgIcon
                icon={Back}
                className="cursor-pointer"
                onClick={backToFirstStep}
              />
            )}
            <SvgIcon
              icon={Close}
              className="cursor-pointer"
              onClick={handleCloseForm}
            />
          </div>
          <h2 className={`${classes.title} mb-4`}>
            Do you have any questions?
          </h2>

          <FormProvider {...formMethods}>
            {currentStep === stepsConfig.first && (
              <FirstStep
                onSubmit={formMethods.handleSubmit(submitFirstStep)}
                formMethods={formMethods}
              />
            )}
            {currentStep === stepsConfig.second && (
              <SecondStep
                reCaptchaToken={reCaptchaToken}
                onReCaptchaVerify={reCaptchaVerify}
                control={formMethods.control}
                onSubmit={formMethods.handleSubmit(submitSecondStep)}
                formMethods={formMethods}
              />
            )}
          </FormProvider>
        </>
      )}
      <div className="flex-fill d-flex flex-column justify-content-end">
        <p className={classes.infoBlock}>
          We are located in Zaporizhzhia, Ukraine. Also you can contact us via{" "}
          <a className={classes.infoLink} href="tel:+380953159373">
            phone
          </a>{" "}
          or via{" "}
          <a
            className={classes.infoLink}
            href="skype:live:e4187abd1073736c?chat"
          >
            Skype
          </a>
          .
        </p>
      </div>
    </>
  )
}

ContactForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFormSentSuccessfully: PropTypes.bool.isRequired,
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
}

export default ContactForm
